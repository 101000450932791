import React, { useRef, useState, useEffect } from "react";
import { Answer, AnswerError } from "../../components/Answer";
import { ExampleList } from "../../components/Example";
import { QuestionInput } from "../../components/QuestionInput";
import { connect } from "react-redux";
import { UserChatMessage } from "../../components/UserChatMessage";
import { getActiveUserSession } from "redux/actions/usersession/usersession.actions";
import { selectors as userSessionSelector } from "redux/reducers/usersession/usersession_reducer";
import globesmall from "assets/images/globe_small.svg";

import LABELS from "labels";
import { LoadingDotsFlashing } from "components/Loading/LoadingDotsFlashing";
import { Layout } from "containers/Layout";
import { SearchResults } from "containers/SearchResults";
import { useDispatch, useSelector } from "react-redux";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { TAXONOMY, RESPONSE_DATA, ROLES } from "redux/constants";
import SampleQueryArrow from "assets/images/LineArrow.svg";
import Image from "components/shared/Image";
import logoIcon from "assets/images/keyword_search_home.svg";
import BenefitContent from "assets/images/benefit-content.svg";
import CONFIG from "config";
import CONSTANTS from "globalConstants";
import { sendToLocalStorage, getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';

import "./KeywordSearch.scss";
import { UpdatePageQueryParamsWithoutRefresh } from "utils/helpers/helpers";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    RETRIEVAL_API_RESULTS_SUCCESS,
    CLEAR_TRANSCRIPT_SELECTED,
    SET_LATEST_SEARCH_TEXT,
    CHAT_CLEAR_ALL_MESSAGES,
    QUERY_CHAT_API,
    CHAT_SUCCESS,
    REMOVE_FETCH_CONTROLLER,
    MONTH_OF_INTERVIEW_VALUE,
    COMPANY_FILTER_APPLIED,
    SOURCE_FILTER_APPLIED,
    INDUSTRY_FILTER_APPLIED,
    FUNCTION_FILTER_APPLIED,
    CHAT_SET_HISTORY_ID,
    QUERY_RESPONSE,
    SET_CURRENT_PAGE,
    SET_SORT_STATE,
    SET_IS_LAST_PAGE,
    SET_FILTERS
} = RESPONSE_DATA;

const {
    API_STATUS: {
        COMPLETED,
        ERROR,
        START,
        PENDING
    },
    ENTITLEMENTS: {
        TRANSCRIPT_LIBRARY,
        DOWNLOAD,
        SEARCH
    },
} = CONSTANTS;

const { RESET_ALL_SL_PARENT_TAGS } = TAXONOMY;
const KeywordSearchContainer = ({
    activeUserSessionId,
    history,
    getCurrentUserSession,
    match,
    match: {
        params: { sessionId },
    },
    entitlementApiStatus,
    isDownloadEnabled,
    isSearchEnabled,
    location
}) => {
    const { TL_GPT_ENGINE, NUM_OF_TRANSCRIPTS_TEXT } = CONFIG;
    const question = new URLSearchParams(location.search).get("query");
    const {
        CHAT_EMPTY_TITLE,
        CHAT_EMPTY_SUBTITLE,
        INPUT_EMPTY_HEADING,
        INPUT_HEADING,
        CLOSE
    } = LABELS;
    const dispatch = useDispatch();
    const isResponseLoading = useSelector(responseSelector.getLoading);
    const queryResponse = useSelector(responseSelector.getResponse);
    const queryResponseError = useSelector(responseSelector.getError)
    const errorMessage = useSelector(responseSelector.getErrorMessage)
    const filters = useSelector(responseSelector.getFilters);
    const sources = filters.sources;
    const messages = useSelector(responseSelector.getMessages)
    const chatHistoryId = useSelector(responseSelector.getChatHistoryId)
    const lastUserQues = messages.filter(m => m.role === ROLES.USER)?.pop()?.content;
    const fetchController = useSelector(responseSelector.getFetchController)
    const isChatBotScreen = window.location.search !== "";
    const lastQuestionRef = useRef("");
    const chatMessageStreamEnd = useRef(null);
    const pageURL = window.location.href;

    const [performChat, setPerformChat] = useState(false);

    const chatContainerElementRef = useRef(null);

    const makeApiRequest = (question, isRetry, pageReload) => {
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: window.location.origin + window.location.pathname + `?query=${question}`
                }
            },
            internal: {
                search: { term: question }
            }
        };
        UpdatePageQueryParamsWithoutRefresh('/', history, `?query=${question}`);
        setTimeout(function () {
            ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.INTERNAL_SEARCH)
        }, 0);

        lastQuestionRef.current = question;
        // dispatch({
        //     type: QUERY_RESPONSE, payload: {
        //         question, resetFilters: true
        //     }
        // });
        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: [] } });
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({
            type: SET_LATEST_SEARCH_TEXT, payload: {
                response: question
            }
        });
        dispatch({ type: SET_CURRENT_PAGE, payload: { response: 0 } });
        dispatch({ type: SET_SORT_STATE, payload: { response: "Most Relevant" } });
        dispatch({ type: SET_IS_LAST_PAGE, payload: { response: false } });
        dispatch({
            type: QUERY_RESPONSE, payload: {
                question, resetFilters: true, currentPage: 0, dispatch, pageReload
            }
        });

        dispatch({ type: MONTH_OF_INTERVIEW_VALUE, payload: { response: CONFIG.DATE_FILTER_OPTIONS[0] } });
        dispatch({ type: COMPANY_FILTER_APPLIED, payload: { response: 0 } });
        dispatch({ type: SOURCE_FILTER_APPLIED, payload: { response: [] } });
        dispatch({ type: INDUSTRY_FILTER_APPLIED, payload: { response: 0 } });
        dispatch({ type: FUNCTION_FILTER_APPLIED, payload: { response: 0 } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });
        const newSourceList = [...sources];
        newSourceList.map(status => {
            status.selected = false
        })
        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });
        dispatch({
            type: SET_FILTERS, payload: {
                response: {
                    monthOfInterviewRange: {
                        startMonth: null,
                        endMonth: null
                    }
                }
            }
        });
    };

    const clearChat = (source) => {
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: window.location.origin + window.location.pathname
                }
            },
            linkName: source === "newChat" ? "New Chat" : "Home",
            link: { toolSearch: "NA" },
            internal: {
                search: { term: "NA" }
            }

        };
        if (lastQuestionRef.current) {
            // ANALYTICS.page.initialize({
            //     pageName: PAGES.Transcript_Library_Keyword_Search,
            //     previousPageName: PAGES.Transcript_Library_Keyword_Search,
            //     category: PRIMARY_CATEGORY.Transcript_Library,
            //     trackPageLoad: true
            // });
        }
        lastQuestionRef.current = "";
        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: [] } });
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: CHAT_CLEAR_ALL_MESSAGES, payload: { response: "" } });
        dispatch({ type: CHAT_SUCCESS, payload: {} });
        dispatch({ type: CHAT_SET_HISTORY_ID, payload: { response: null } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });
        if (fetchController !== null) {
            fetchController.abort('User clicked stop generating');
        }

        dispatch({ type: REMOVE_FETCH_CONTROLLER });
        UpdatePageQueryParamsWithoutRefresh('/', history, ``);
        setTimeout(function () { ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.BUTTON_LINK_CLICKS) }, 0);
    };

    useEffect(() => {
        console.log('useEffect 1');
        if (performChat && messages[messages.length - 1]?.role === ROLES.USER) {
            setPerformChat(false);
            dispatch({ type: QUERY_CHAT_API, payload: { messages, selectedEngine: TL_GPT_ENGINE, chatHistoryId, dispatch } });
            //dispatch(chatActions.streamChat(messages, selectedEngine, scrollChat));
        }
        if (messages.length === 0) {
            //setIsSourcesCollapseOpen({});
        }

        handleScrollToActiveMessage();
    }, [performChat, messages]);

    useEffect(() => {
        const eVar2 = getFromLocalStorageAsJSON("eVar2ForPreviousPage");
        removeFromLocalStorage("eVar2ForPreviousPage");

        if (!activeUserSessionId && !sessionId) {
            getCurrentUserSession();
        }
        if ((![START, PENDING].includes(entitlementApiStatus)) && isSearchEnabled) {
            if (question) {
                ANALYTICS.page.initialize({
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    category: PRIMARY_CATEGORY.Transcript_Library, trackPageLoad: true
                });
                makeApiRequest(question, undefined, false);
            } else {
                ANALYTICS.page.initialize({
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: eVar2 === "fromChatBot" ? PAGES.Transcript_Library_Chatbot : eVar2 === "fromSingleTranscript" ? PAGES.Detailed_Transcript_View : PAGES.Transcript_Library_Keyword_Search,
                    category: PRIMARY_CATEGORY.Transcript_Library, trackPageLoad: true
                });
            }
        }
    }, [entitlementApiStatus]);

    const handleScrollToActiveMessage = () => {
        if (chatContainerElementRef.current) {
            const chatContainer = chatContainerElementRef.current;
            chatContainer.scrollTo({
                top: chatContainer.scrollHeight,
                behavior: "instant"
            })
        }
    }

    const changeRoute = (url = "/") => {
        sendToLocalStorage("eVar2ForPreviousPage", "fromKeywordSearch");
        history.push(url);
    };
    return (
        <Layout id="chat" layoutClass="layout__chat-section" clearChat={clearChat} activeRoute="KeywordSearch" match={match} location={location} history={history}>
            {!lastQuestionRef.current && <div className={"container__keywordsearch"}>
                {/* <div className={"commandsContainer"}>
                    <ClearChatButton className={"commandButton"} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                    <SettingsButton className={"commandButton"} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                </div> */}
                <div className={"chatRoot"}>
                    <div className={`${!lastQuestionRef.current ? "chatContainer remove-padding" : "chatContainer"}`}>
                        {!lastQuestionRef.current &&
                            <div className={"chatEmptyState"}>
                                <div className="headingText">
                                    <h1 className={"chatEmptyStateTitle"}>{CHAT_EMPTY_TITLE}</h1>
                                    <div className="numOfTranscriptsContainer">
                                        <Image className={"numOfTranscriptsImage"} src={BenefitContent} alt="logo" />
                                        <span className={"numOfTranscriptsText"}>{NUM_OF_TRANSCRIPTS_TEXT}</span>
                                    </div>
                                </div>
                                <div className="headingText"></div>
                                <div className="headingImage">
                                    <Image className={"chatEmptyMainImage"} src={logoIcon} alt="logo" />
                                </div>
                                {/* <h2 className={"chatEmptyStateSubtitle"}>{CHAT_EMPTY_SUBTITLE}</h2> */}

                            </div>
                        }
                        {!lastQuestionRef.current}
                        <div className={`chatInput__wrap ${lastQuestionRef.current ? 'bottom' : ''}`}>
                            <div className="chatInput__inner">
                                <div className="tabsHeading">
                                    <div className="tab active">Keyword Search</div>
                                    <div className="tab" onClick={() => changeRoute(CONFIG.UI_URL.CHAT_BOX)}>
                                        <div className="chat-tooltip">Generate an interview guide here!
                                            <Image className="chat-arrow" src={SampleQueryArrow} alt="SampleQueryArrow" />
                                        </div>
                                        Chat
                                    </div>


                                </div>



                                {/* <div className="chatInput__heading"><Image src={globesmall} alt="logo" />{!lastQuestionRef.current ? INPUT_EMPTY_HEADING : INPUT_HEADING}</div> */}
                                <div className={"chatInput"}>
                                    <QuestionInput
                                        questionInput={""}
                                        placeholder="Search for keywords or companies"
                                        disabled={isResponseLoading}
                                        onSend={question => makeApiRequest(question, undefined, true)}
                                    />
                                </div>
                                <span className="chatInput__disclamier"><span>*</span> Do not enter any Client sensitive Information on Chat</span>

                            </div>
                            {lastQuestionRef.current && (<div className="chatInput__newChatButton">
                                <span onClick={() => clearChat("newChat")} className="chatInput__newChat">+ New Chat</span>
                            </div>)}
                            {!lastQuestionRef.current && (
                                <ExampleList
                                    // data={suggestedQueryResponse}
                                    data={["Get smart on general industry knowledge", "Understand specific company perspectives", "Create an interview guide"]}
                                />
                            )}
                        </div>

                    </div>
                </div>
            </div>
            }
            {lastQuestionRef.current && (
                <SearchResults isDownloadEnabled={isDownloadEnabled} question={question} makeApiRequest={makeApiRequest} />
            )}
        </Layout >
    );
};

const mapStateToProps = (state) => ({
    activeUserSessionId: userSessionSelector.getActiveUserSession(state),
    entitlementApiStatus: entitlementSelectors.getApiCallStatus(state),
    isDownloadEnabled: entitlementSelectors.getEntitlementValue(state, TRANSCRIPT_LIBRARY, DOWNLOAD),
    isSearchEnabled: entitlementSelectors.getEntitlementValue(state, TRANSCRIPT_LIBRARY, SEARCH),
});

const mapDispatchToProps = (dispatch) => ({
    getCurrentUserSession: () => dispatch(getActiveUserSession())
});


export const KeywordSearch = connect(
    mapStateToProps,
    mapDispatchToProps
)(KeywordSearchContainer);

export { KeywordSearchContainer };
