import React from "react";

import "./SampleQueries.scss";

export const SampleQueries = ({ data, makeApiRequest }) => {
    return (
        <div className="sampleQueriesList">
            <div className="query-container">
                {data.chatQueries && (
                    <div className="query-category">
                        <div className="query-header">
                            <span>For Chat, try searching</span>
                        </div>
                        <div className="query-buttonsForSearching">
                            {data.chatQueries.map((query, index) => (
                                <div key={index} className="sample-query" onClick={() => makeApiRequest(query, undefined, true)}>
                                    <div>{query}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {data.interviewGuideQueries && (
                    <div className="query-category">
                        <div className="query-header">
                            <span>For Interview Guide, try asking</span>
                        </div>
                        <div className="query-buttons">
                            {data.interviewGuideQueries.map((query, index) => (
                                <div key={index} className="sample-query" onClick={() => makeApiRequest(query, undefined, true)}>
                                    <div>{query}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
