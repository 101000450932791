import React from "react";
import ReactHtmlParser from 'html-react-parser';
import PropTypes from 'prop-types';
import { downloadFileFromUrl } from "utils/helpers/helpers";


import logoIcon from "assets/images/tl_logo.svg";
import Image from "components/shared/Image";
import { sendToLocalStorage } from 'utils/localStorage/localStorage';


// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

import "./Answer.scss";


export const Answer = ({
    answer,
    lastUserQues
}) => {
    const pageURL = window.location.href;
    const adobeAnalytics = (id) => {
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Chatbot,
                    previousPageName: PAGES.Transcript_Library_Chatbot,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            },
            link: { toolSearch: id }

        };

        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.TOOLS_SEARCH_LINK_CLICKS);

    }
    const openTranscriptInNewTab = (id) => {
        sendToLocalStorage("eVar2ForDetailedTranscript", "fromChatBot");
        downloadFileFromUrl(`/transcript/${id}`, "_blank");
        adobeAnalytics(id);
    };

    const openTranscriptInNewTab2 = (e) => {
        if (e.target.tagName === 'A') {
            sendToLocalStorage("eVar2ForDetailedTranscript", "fromChatBot");
            const transcriptIdRegex = /\/transcript\/([a-f0-9-]+)/g;
            const transcriptId = transcriptIdRegex.exec(e.target.href)
            if (transcriptId) {
                adobeAnalytics(transcriptId[1]);
            }
        }
    }

    return (
        <div className={`answerContainer`}>
            <div className="answerContainer__logo-container"> <Image className="answerContainer__logo-icon" src={logoIcon} alt="logo" /></div>

            <div className={`answerContainer__inner`}>
                <div className={"answerText"} onClick={(e) => openTranscriptInNewTab2(e)}> {ReactHtmlParser(answer.content.replace(/\n/ig, '<br />'))}</div>
                {answer.sources && answer.sources.length > 0 &&
                    <div className="answerContainer__attachments">
                        <span className="heading">Related Transcripts:</span>
                        {answer.sources.map((source, index) => (
                            <div className="transcripts" key={source.id} onClick={() => openTranscriptInNewTab(source.id)}>
                                <sup className="reference">{index + 1}</sup>
                                <span className="attachment-name">{decodeURIComponent(source.fileName)}</span>
                            </div>

                        ))}
                    </div>
                }
            </div>
        </div>
    );
};

Answer.propTypes = {
    answer: PropTypes.string.isRequired,
    lastUserQues: PropTypes.string.isRequired,
};