import React, { useEffect } from "react";
import "./Docviz.scss";
import { useDispatch, useSelector } from "react-redux";
import { CardView, CardViewSubject, GlobalStyle, OverlayView, OverlayViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
import authService from 'redux/auth';
import { useState } from "react";
import { RESPONSE_DATA, ROLES } from "redux/constants";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { DOCVIZ } from "redux/constants";
import { DocvizOverlayView } from "./DocvizOverlayView";
// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

export const Docviz = ({ doc, downloadClicked, transcript, isDownloadEnabled }) => {
    const pageURL = window.location.href;
    const [docvizOpen, setDocvizOpen] = useState(false);
    const lastUserQues = useSelector(responseSelector.getLatestSearchText);
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const monthOfInterviewRangeValue = useSelector(responseSelector.getMonthOfInterviewRangeValue);
    const filters = useSelector(responseSelector.getFilters);
    const groupedCompanies = filters.groupedCompanies || [];
    const companyList = [];
    const pathname = window.location.pathname;
    const sourceFilterApplied = useSelector(responseSelector.getSourceFilterApplied);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const filteredIndustryTags = filters.filteredIndustryTags || [];
    const filteredFunctionTags = filters.filteredFunctionTags || [];
    const industryTagsList = [];
    filteredIndustryTags.map(it => {
        if (it.checked) {
            industryTagsList.push(it.tagName);
        }
        it.children && it.children.map(it1 => {
            if (it1.checked) {
                industryTagsList.push(it1.tagName);
            }
            it1.children && it1.children.map(it2 => {
                if (it2.checked) {
                    industryTagsList.push(it2.tagName);
                }
            })
        })
    })

    const functionTagsList = [];
    filteredFunctionTags.map(it => {
        if (it.checked) {
            functionTagsList.push(it.tagName);
        }
        it.children && it.children.map(it1 => {
            if (it1.checked) {
                functionTagsList.push(it1.tagName);
            }
            it1.children && it1.children.map(it2 => {
                if (it2.checked) {
                    functionTagsList.push(it2.tagName);
                }
            })
        })
    })
    groupedCompanies.map(group => {
        group.children.map(status => {
            if (status.selected) {
                companyList.push(status.key.substring(0, status.key.lastIndexOf(" (")));
            }
        })
    })
    const dispatch = useDispatch();
    const apiConfig = {
        key: process.env.REACT_APP_TL_ENRICH_API_KEY, // used to fetch a document
        token: () => authService.getAccessToken(), // an async function returning the user's bearer token, just the token, less 'Bearer'
        env: "" // the prefix for links, prepends to `${env}.bcg.com/xxx`
    }

    const actions = {
        showPreview: true,
        showEyePreview: false,
        showDownload: isDownloadEnabled,
        showShare: false,
        showEmail: false
    }
    let cardSubscriber;
    useEffect(() => {
        cardSubscriber = CardViewSubject.subscribe(action => {
            let newAdobeData;
            const pageName = pathname === '/' ? PAGES.Transcript_Library_Keyword_Search : pathname.includes('chat') ? PAGES.Transcript_Library_Chatbot : PAGES.Detailed_Transcript_View;
            switch (action.type) {
                case SUBSCRIBERS.PREVIEW_CLICK:
                    setDocvizOpen(true);
                    newAdobeData = {
                        page: {
                            category: {
                                primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                            },
                            pageInfo: {
                                pageName: pageName,
                                previousPageName: pageName,
                                pageURL: pageURL
                            }
                        },
                        internal: {
                            search: { term: lastUserQues }
                        },
                        link: { toolSearch: doc.kpId },
                        filterCategory: "",
                        filterChecked: ""
                    };
                    if (companyFilterApplied > 0) {
                        newAdobeData.filterCategory += "Company Name | ";
                        newAdobeData.filterChecked += companyList.join(" , ") + " | ";
                    }
                    if (monthOfInterviewRangeValue.key !== '') {
                        newAdobeData.filterCategory += "Month of Interview | ";
                        newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
                    }
                    if (sourceFilterApplied.length > 0) {
                        newAdobeData.filterCategory += "Source | ";
                        newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
                    }
                    if (industryFilterApplied > 0) {
                        newAdobeData.filterCategory += "IndustryPA | ";
                        newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
                    }
                    if (functionFilterApplied > 0) {
                        newAdobeData.filterCategory += "FunctionPA | ";
                        newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
                    }
                    ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.PREVIEW_DOCUMENT);
                    break;
                case SUBSCRIBERS.DOWNLOAD_CLICK:
                    downloadClicked(action.doc, false);
                    newAdobeData = {
                        page: {
                            category: {
                                primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                            },
                            pageInfo: {
                                pageName: pageName,
                                previousPageName: pageName,
                                pageURL: pageURL
                            }
                        },
                        internal: {
                            search: { term: lastUserQues }
                        },
                        iconName: "Download",
                        link: { toolSearch: doc.kpId },
                        filterCategory: "",
                        filterChecked: ""
                    };
                    if (companyFilterApplied > 0) {
                        newAdobeData.filterCategory += "Company Name | ";
                        newAdobeData.filterChecked += companyList.join(" , ") + " | ";
                    }
                    if (monthOfInterviewRangeValue.key !== '') {
                        newAdobeData.filterCategory += "Month of Interview | ";
                        newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
                    }
                    if (sourceFilterApplied.length > 0) {
                        newAdobeData.filterCategory += "Source | ";
                        newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
                    }
                    if (industryFilterApplied > 0) {
                        newAdobeData.filterCategory += "IndustryPA | ";
                        newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
                    }
                    if (functionFilterApplied > 0) {
                        newAdobeData.filterCategory += "FunctionPA | ";
                        newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
                    }
                    ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.ICON_ACTION_CLICKS);
                    break;
                default:
                    break;
            }
        });


        return () => {
            if (cardSubscriber) {
                cardSubscriber.unsubscribe();
            }
        }
    })
    return (
        <div className="docviz-wrapper">
            {/* <Image src={DocvizPreview} alt="logo" /> */}
            <CardView
                doc={doc}
                apiConfig={apiConfig}
                entitled={true}
                isHideExpandText={true}
                actions={actions}
            />

            {docvizOpen && <DocvizOverlayView
                doc={doc}
                apiConfig={apiConfig}
                actions={actions}
                downloadClicked={downloadClicked}
                transcript={transcript}
                setDocvizOpen={setDocvizOpen}
                isDownloadEnabled={isDownloadEnabled}
            />}
        </div>
    );
};
