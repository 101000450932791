import React, { useEffect, useRef } from "react";
import "./MonthOfInterview.scss";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import {
    RESPONSE_DATA, INFO, TAXONOMY
} from "redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { isInViewport, scrollToTop } from "utils/helpers/helpers";
import CONFIG from "config";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    CLEAR_TRANSCRIPT_SELECTED,
    MONTH_OF_INTERVIEW_VALUE,
    SET_CURRENT_PAGE,
    QUERY_RESPONSE
} = RESPONSE_DATA;

const {
    RESET_ALL_SL_PARENT_TAGS
} = TAXONOMY;

export const MonthOfInterviewFilter = ({ showMonthOfInterviewFilterContainer, handleFilterIconClick, monthOfInterviewRangeValue, lastUserQues }) => {

    const dispatch = useDispatch();
    const pageURL = window.location.href;
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const selectedSortOption = useSelector(responseSelector.getSelectedSortOption);
    const sourceFilterApplied = useSelector(responseSelector.getSourceFilterApplied);
    const actionButtonContainerElementRef = useRef(null);
    let dateFilterRef = useRef();

    useEffect(() => {
        if (showMonthOfInterviewFilterContainer && actionButtonContainerElementRef.current) {
            const actionButtonContainer = actionButtonContainerElementRef.current;
            let windowScroll = isInViewport(actionButtonContainer);
            let existingScroll = window.scrollY;
            scrollToTop(existingScroll + windowScroll);
        }
    }, [showMonthOfInterviewFilterContainer]);

    useEffect(() => {
        let filterHandler = (e) => {
            if (!dateFilterRef.current.contains(e.target) && e.target.className !== 'filter date')
                handleFilterIconClick(false)

        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })

    const handleApplyClick = (so) => {
        if (so.key !== monthOfInterviewRangeValue.key) {
            dispatch({ type: MONTH_OF_INTERVIEW_VALUE, payload: { response: so } });

            const newAdobeData = {
                page: {
                    category: {
                        primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                    },
                    pageInfo: {
                        pageName: PAGES.Transcript_Library_Keyword_Search,
                        previousPageName: PAGES.Transcript_Library_Keyword_Search,
                        pageURL: pageURL
                    }
                },
                internal: {
                    search: { term: lastUserQues }
                }
            };
            newAdobeData.filterCategory = "Month of Interview";
            newAdobeData.filterChecked = "";

            handleFilterIconClick(false);
            dispatch({
                type: QUERY_RESPONSE, payload: {
                    question: lastUserQues,
                    resetFilters: true,
                    sort: selectedSortOption,
                    currentPage: 0,
                    dispatch,
                    selectedFilters: {
                        monthOfInterview: so.key,
                        sources: sourceFilterApplied
                    }
                }
            });
            dispatch({ type: SET_CURRENT_PAGE, payload: { response: 0 } });
            dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
            dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });


            newAdobeData.filterChecked = so.value;
            ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.FILTER_CLICKS);
            //dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: transcriptResults } });
            if (companyFilterApplied > 0 || industryFilterApplied > 0 || functionFilterApplied > 0) {
                let notifyMessage = "The existing filters have been cleared.";
                dispatch({ type: INFO, payload: { title: "INFO", message: notifyMessage } });
            }
        }
    }

    return (
        <div ref={dateFilterRef} className="date-filter-container">
            <div className='filter-container'>
                <div className='sort-list-container'>
                    {CONFIG.DATE_FILTER_OPTIONS.map(so => (
                        <div key={so.key} className={`filter-item ${so.key === monthOfInterviewRangeValue.key && "selected"}`}>
                            <span className="" onClick={() => handleApplyClick(so)}>{so.value}</span>
                        </div>))
                    }
                </div>
            </div>
        </div >

    )
};
