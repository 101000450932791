export const RESPONSE_DATA = {
  QUERY_RESPONSE: "QUERY_RESPONSE",
  RESPONSE_API_RESULTS_PENDING: "RESPONSE_API_RESULTS_PENDING",
  RESPONSE_API_SCROLL_LOADING: "RESPONSE_API_SCROLL_LOADING",
  RESPONSE_API_RESULTS_SUCCESS: "RESPONSE_API_RESULTS_SUCCESS",
  RESPONSE_API_RESULTS_FAILURE: "RESPONSE_API_RESULTS_FAILURE",
  RETRIEVAL_API_RESULTS_SUCCESS: "RETRIEVAL_API_RESULTS_SUCCESS",
  UPDATE_TRANSCRIPT_IN_QUERY_RESULTS: "UPDATE_TRANSCRIPT_IN_QUERY_RESULTS",
  CLEAR_TRANSCRIPT_SELECTED: "CLEAR_TRANSCRIPT_SELECTED",
  UPDATE_TRANSCRIPT_SELECTED: "UPDATE_TRANSCRIPT_SELECTED",
  SELECT_TRANSCRIPT: "SELECT_TRANSCRIPT",
  SET_LATEST_SEARCH_TEXT: "SET_LATEST_SEARCH_TEXT",
  GET_SELECTED_TRANSCRIPT: "GET_SELECTED_TRANSCRIPT",
  GET_DOCVIZ_REQUEST_IDS: "GET_DOCVIZ_REQUEST_IDS",
  GET_DOWNLOAD_COUNT: "GET_DOWNLOAD_COUNT",
  GET_TRANSCRIPT_DETAIL: "GET_TRANSCRIPT_DETAIL",
  GET_FILE: "GET_FILE",
  GET_FILE_PENDING: "GET_FILE_PENDING",
  GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
  GET_FILE_FAILURE: "GET_FILE_FAILURE",
  QUERY_CHAT_API: "QUERY_CHAT_API",
  CHAT_PENDING: 'CHAT_PENDING',
  CHAT_SUCCESS: 'CHAT_SUCCESS',
  CHAT_FAILURE: 'CHAT_FAILURE',
  CHAT_SOURCES_SUCCESS: 'CHAT_SOURCES_SUCCESS',
  CHAT_SOURCES_PENDING: 'CHAT_SOURCES_PENDING',
  CHAT_SOURCES_FAILURE: 'CHAT_SOURCES_FAILURE',
  CHAT_SET_HISTORY_ID: 'CHAT_SET_HISTORY_ID',
  CHAT_CLEAR_ALL_MESSAGES: 'CHAT_CLEAR_ALL_MESSAGES',
  RESET_CHAT_STATE: 'RESET_CHAT_STATE',
  SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS: 'SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS',
  CHAT_STREAM_CHUNK: 'CHAT_STREAM_CHUNK',
  CHAT_STREAM_NEW_MESSAGE: 'CHAT_STREAM_NEW_MESSAGE',
  CHAT_ADD_MESSAGE: 'CHAT_ADD_MESSAGE',
  CHAT_DELETE_MESSAGE: 'CHAT_DELETE_MESSAGE',
  SET_FETCH_CONTROLLER: 'SET_FETCH_CONTROLLER',
  REMOVE_FETCH_CONTROLLER: 'REMOVE_FETCH_CONTROLLER',
  SET_FILTERS: "SET_FILTERS",
  COMPANY_FILTER_APPLIED: "COMPANY_FILTER_APPLIED",
  SOURCE_FILTER_APPLIED: "SOURCE_FILTER_APPLIED",
  INDUSTRY_FILTER_APPLIED: "INDUSTRY_FILTER_APPLIED",
  FUNCTION_FILTER_APPLIED: "FUNCTION_FILTER_APPLIED",
  MONTH_OF_INTERVIEW_VALUE: "MONTH_OF_INTERVIEW_VALUE",
  INTERNAL_TAGS_API_RESULTS_PENDING: "INTERNAL_TAGS_API_RESULTS_PENDING",
  INTERNAL_TAGS_API_RESULTS_SUCCESS: "INTERNAL_TAGS_API_RESULTS_SUCCESS",
  INTERNAL_TAGS_API_RESULTS_FAILURE: "INTERNAL_TAGS_API_RESULTS_FAILURE",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_SORT_STATE: "SET_SORT_STATE",
  SET_IS_LAST_PAGE: "SET_IS_LAST_PAGE",
  SET_BOOKMARK: "SET_BOOKMARK",
  SET_BOOKMARK_API_RESULTS_PENDING: "SET_BOOKMARK_API_RESULTS_PENDING",
  SET_BOOKMARK_API_RESULTS_SUCCESS: "SET_BOOKMARK_API_RESULTS_SUCCESS",
  SET_BOOKMARK_API_RESULTS_FAILURE: "SET_BOOKMARK_API_RESULTS_FAILURE",
  DELETE_BOOKMARK: "DELETE_BOOKMARK",
  DELETE_BOOKMARK_API_RESULTS_PENDING: "DELETE_BOOKMARK_API_RESULTS_PENDING",
  DELETE_BOOKMARK_API_RESULTS_SUCCESS: "DELETE_BOOKMARK_API_RESULTS_SUCCESS",
  DELETE_BOOKMARK_API_RESULTS_FAILURE: "DELETE_BOOKMARK_API_RESULTS_FAILURE",
  DELETE_ALL_BOOKMARK: "DELETE_ALL_BOOKMARK",
  DELETE_ALL_BOOKMARK_API_RESULTS_PENDING: "DELETE_ALL_BOOKMARK_API_RESULTS_PENDING",
  DELETE_ALL_BOOKMARK_API_RESULTS_SUCCESS: "DELETE_ALL_BOOKMARK_API_RESULTS_SUCCESS",
  DELETE_ALL_BOOKMARK_API_RESULTS_FAILURE: "DELETE_ALL_BOOKMARK_API_RESULTS_FAILURE",
  GET_ALL_BOOKMARK: "GET_ALL_BOOKMARK",
  GET_ALL_BOOKMARK_API_RESULTS_PENDING: "GET_ALL_BOOKMARK_API_RESULTS_PENDING",
  GET_ALL_BOOKMARK_API_RESULTS_SUCCESS: "GET_ALL_BOOKMARK_API_RESULTS_SUCCESS",
  GET_ALL_BOOKMARK_API_RESULTS_FAILURE: "GET_ALL_BOOKMARK_API_RESULTS_FAILURE",
  SEARCH_BOOKMARK: "SEARCH_BOOKMARK",
  SEARCH_BOOKMARK_PENDING: "SEARCH_BOOKMARK_PENDING",
  GET_ALL_KEYWORD_SEARCH_HISTORY: "GET_ALL_KEYWORD_SEARCH_HISTORY",
  GET_ALL_KEYWORD_SEARCH_HISTORY_API_RESULTS_PENDING: "GET_ALL_KEYWORD_SEARCH_HISTORY_API_RESULTS_PENDING",
  GET_ALL_KEYWORD_SEARCH_HISTORY_API_RESULTS_SUCCESS: "GET_ALL_KEYWORD_SEARCH_HISTORY_API_RESULTS_SUCCESS",
  GET_ALL_KEYWORD_SEARCH_HISTORY_API_RESULTS_FAILURE: "GET_ALL_KEYWORD_SEARCH_HISTORY_API_RESULTS_FAILURE",
  DELETE_KEYWORD_SEARCH_HISTORY: "DELETE_KEYWORD_SEARCH_HISTORY",
  GET_CHAT_SEARCH_HISTORY: "GET_CHAT_SEARCH_HISTORY",
  GET_CHAT_SEARCH_HISTORY_API_RESULTS_PENDING: "GET_CHAT_SEARCH_HISTORY_API_RESULTS_PENDING",
  GET_CHAT_SEARCH_HISTORY_API_RESULTS_SUCCESS: "GET_CHAT_SEARCH_HISTORY_API_RESULTS_SUCCESS",
  GET_CHAT_SEARCH_HISTORY_API_RESULTS_FAILURE: "GET_CHAT_SEARCH_HISTORY_API_RESULTS_FAILURE",
  DELETE_CHAT_SEARCH_HISTORY: "DELETE_CHAT_SEARCH_HISTORY",
  GET_CHAT_SEARCH_HISTORY_BY_ID: "GET_CHAT_SEARCH_HISTORY_BY_ID",
  GET_CHAT_SEARCH_HISTORY_BY_ID_RESULTS_PENDING: "GET_CHAT_SEARCH_HISTORY_BY_ID_RESULTS_PENDING",
  GET_CHAT_SEARCH_HISTORY_BY_ID_RESULTS_SUCCESS: "GET_CHAT_SEARCH_HISTORY_BY_ID_RESULTS_SUCCESS",
  GET_CHAT_SEARCH_HISTORY_BY_ID_RESULTS_FAILURE: "GET_CHAT_SEARCH_HISTORY_BY_ID_RESULTS_FAILURE",
  SEARCH_KEYWORD_HISTORY: "SEARCH_KEYWORD_HISTORY",
  SEARCH_KEYWORD_HISTORY_PENDING: "SEARCH_KEYWORD_HISTORY_PENDING",
  SEARCH_CHAT_HISTORY: "SEARCH_CHAT_HISTORY",
  DELETE_ALL_CHAT_SEARCH_HISTORY: "DELETE_ALL_CHAT_SEARCH_HISTORY",
  DELETE_ALL_KEYWORD_SEARCH_HISTORY: "DELETE_ALL_KEYWORD_SEARCH_HISTORY",
  SEARCH_CHAT_HISTORY_PENDING: "SEARCH_CHAT_HISTORY_PENDING",
  GET_KEY_INSIGHTS: "GET_KEY_INSIGHTS",
  GET_KEY_INSIGHTS_RESULTS_PENDING: "GET_KEY_INSIGHTS_RESULTS_PENDING",
  GET_KEY_INSIGHTS_RESULTS_SUCCESS: "GET_KEY_INSIGHTS_RESULTS_SUCCESS",
  GET_KEY_INSIGHTS_RESULTS_FAILURE: "GET_KEY_INSIGHTS_RESULTS_FAILURE",
}

export const ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
  STATUS: 'status'
};

export const SUGGESTED_QUERY_DATA = {
  SUGGESTED_QUERY: "SUGGESTED_QUERY",
  SUGGESTED_QUERY_API_RESULTS_PENDING: "SUGGESTED_QUERY_API_RESULTS_PENDING",
  SUGGESTED_QUERY_API_RESULTS_SUCCESS: "SUGGESTED_QUERY_API_RESULTS_SUCCESS",
  SUGGESTED_QUERY_API_RESULTS_FAILURE: "SUGGESTED_QUERY_API_RESULTS_FAILURE"
}

// Notification toastify
export const ERROR = "ERROR";
export const INFO = "INFO";
export const SUCCESS = "SUCCESS";
export const WARNING = "WARNING";
export const DISMISS_NOTIFICATIONS = "DISMISS_NOTIFICATIONS";

// Upload
export const UPLOAD_S3 = {
  GET_UPLOAD_S3: "GET_UPLOAD_S3",
  GET_UPLOAD_S3_PENDING: "GET_UPLOAD_S3_PENDING",
  GET_UPLOAD_S3_SUCCESS: "GET_UPLOAD_S3_SUCCESS",
  GET_UPLOAD_S3_FAILURE: "GET_UPLOAD_S3_FAILURE",
  FETCH_PRESIGNURL_PENDING: "FETCH_PRESIGNURL_PENDING",
  FETCH_PRESIGNURL_FAILURE: "FETCH_PRESIGNURL_FAILURE",
  FETCH_PRESIGNURL_SUCCESS: "FETCH_PRESIGNURL_SUCCESS",
}
// Taxonomy Actions
export const TAXONOMY = {
  GET_TAXONOMY: "GET_TAXONOMY",
  GET_TYPE_SEARCH_DATA: "GET_TYPE_SEARCH_DATA",
  API_GET_TAXONOMY_PENDING: "API_GET_FUNCTIONAL_TAXONOMY_PENDING",
  API_GET_TAXONOMY_SUCCESS: "API_GET_FUNCTIONAL_TAXONOMY_SUCCESS",
  API_GET_TAXONOMY_FAILURE: "API_GET_FUNCTIONAL_TAXONOMY_FAILURE",

  API_GET_TYPESEARCH_PENDING: "API_GET_FUNCTIONAL_TYPESEARCH_PENDING",
  API_GET_TYPESEARCH_SUCCESS: "API_GET_FUNCTIONAL_TYPESEARCH_SUCCESS",
  API_GET_TYPESEARCH_FAILURE: "API_GET_FUNCTIONAL_TYPESEARCH_FAILURE",

  GET_SL_PARENT_TAGS_DATA: "GET_SL_PARENT_TAGS_DATA",
  GET_SL_PARENT_TAGS_PENDING: "GET_SL_PARENT_TAGS_PENDING",
  GET_SL_PARENT_TAGS_SUCCESS: "GET_SL_PARENT_TAGS_SUCCESS",
  GET_SL_PARENT_TAGS_FAILURE: "GET_SL_PARENT_TAGS_FAILURE",
  RESET_ALL_SL_PARENT_TAGS: "RESET_ALL_SL_PARENT_TAGS",

  CREATE_POLY_HIERARCHI_GUIDS_OBJ_CONFIG: "CREATE_POLY_HIERARCHI_GUIDS_OBJ_CONFIG"
};

export const API_GET_ALL_MATERIALS_PENDING = "API_GET_ALL_MATERIALS_PENDING";
export const API_GET_ALL_MATERIALS_FAILURE = "API_GET_ALL_MATERIALS_FAILURE";
export const API_GET_ALL_MATERIALS_SUCCESS = "API_GET_ALL_MATERIALS_SUCCESS";

export const API_GET_MATERIAL_PENDING = "API_GET_MATERIAL_PENDING";
export const API_GET_MATERIAL_FAILURE = "API_GET_MATERIAL_FAILURE";
export const API_GET_MATERIAL_SUCCESS = "API_GET_MATERIAL_SUCCESS";

export const API_SAVE_MATERIAL_PENDING = "API_SAVE_MATERIAL_PENDING";
export const API_SAVE_MATERIAL_SUCCESS = "API_SAVE_MATERIAL_SUCCESS";
export const API_SAVE_MATERIAL_FAILURE = "API_SAVE_MATERIAL_FAILURE";

export const API_SAVE_UPLOAD_MATERIAL_PENDING =
  "API_SAVE_UPLOAD_MATERIAL_PENDING";
export const API_SAVE_UPLOAD_MATERIAL_SUCCESS =
  "API_SAVE_UPLOAD_MATERIAL_SUCCESS";
export const API_SAVE_UPLOAD_MATERIAL_FAILURE =
  "API_SAVE_UPLOAD_MATERIAL_FAILURE";

export const REDIRECT_METADATA_PAGE = "REDIRECT_METADATA_PAGE";


// Enitilement Actions
export const ENTITLEMENTS = {
  GET_ENTITLEMENTS: "GET_ENTITLEMENTS",
  API_GET_ENTITLEMENTS_PENDING: "API_GET_ENTITLEMENTS_PENDING",
  API_GET_ENTITLEMENTS_SUCCESS: "API_GET_ENTITLEMENTS_SUCCESS",
  API_GET_ENTITLEMENTS_FAILURE: "API_GET_ENTITLEMENTS_FAILURE",

  // Actions for checking whether the user is site admin/editor or not
  CHECK_SITE_ADMIN: "CHECK_SITE_ADMIN",
  API_CHECK_SITE_ADMIN_PENDING: "API_CHECK_SITE_ADMIN_PENDING",
  API_CHECK_SITE_ADMIN_SUCCESS: "API_CHECK_SITE_ADMIN_SUCCESS",
  API_CHECK_SITE_ADMIN_FAILURE: "API_CHECK_SITE_ADMIN_FAILURE",
}


// Global Actions
export const GLOBAL = {
  SHOW_PAGELOADER: "SHOW_PAGELOADER",
  HIDE_PAGELOADER: "HIDE_PAGELOADER",
  SHOW_FULLPAGELOADER: "SHOW_FULLPAGELOADER",
  HIDE_FULLPAGELOADER: "HIDE_FULLPAGELOADER",
  FULLPAGELOADER_ERROR: "FULLPAGELOADER_ERROR",
  DISABLE_BODY_OVERFLOW: "DISABLE_BODY_OVERFLOW",
  ENABLE_BODY_OVERFLOW: "ENABLE_BODY_OVERFLOW",
  LOGGEDIN_USER_DATA: "LOGGEDIN_USER_DATA"
};

export const PEOPLE_DATA = {
  API_PEOPLE_RESULTS_PENDING: "API_PEOPLE_RESULTS_PENDING",
  API_PEOPLE_RESULTS_SUCCESS: "API_PEOPLE_RESULTS_SUCCESS",
  API_PEOPLE_RESULTS_FAILURE: "API_PEOPLE_RESULTS_FAILURE",
  API_TYPEAHEAD_DATA_SUCCESS: "API_TYPEAHEAD_DATA_SUCCESS"
};

export const CCO_DATA = {
  GET_CCO_RESULTS: "GET_CCO_RESULTS",
  GET_CCO_RESULTS_PENDING: "GET_CCO_RESULTS_PENDING",
  GET_CCO_RESULTS_SUCCESS: "GET_CCO_RESULTS_SUCCESS",
  GET_CCO_RESULTS_FAILURE: "GET_CCO_RESULTS_FAILURE",
};


export const USER_SESSION = {
  GET_ACTIVE_USER_SESSION: "GET_ACTIVE_USER_SESSION",
  ACTIVE_USER_SESSION_PENDING: "GET_ACTIVE_USER_SESSION_PENDING",
  ACTIVE_USER_SESSION_SUCCESS: "GET_ACTIVE_USER_SESSION_SUCCESS",
  ACTIVE_USER_SESSION_FAILURE: "GET_ACTIVE_USER_SESSION_FAILURE",
};

// Authorization Actions
export const AUTH = {
  GET_SMARTLOGIC_BEARER_TOKEN: "GET_SMARTLOGIC_BEARER_TOKEN",
  GET_SMARTLOGIC_BEARER_TOKEN_PENDING: "GET_SMARTLOGIC_BEARER_TOKEN_PENDING",
  GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS: "GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS",
  GET_SMARTLOGIC_BEARER_TOKEN_FAILURE: "GET_SMARTLOGIC_BEARER_TOKEN_FAILURE"
};

export const TYPEAHEAD_DATA = {
  API_TYPEAHEAD_RESULTS_PENDING: "API_PEOPLE_RESULTS_PENDING",
  API_TYPEAHEAD_RESULTS_FAILURE: "API_PEOPLE_RESULTS_FAILURE",
  API_TYPEAHEAD_DATA_SUCCESS: "API_TYPEAHEAD_DATA_SUCCESS",
  API_TYPEAHEAD_DATA_CLEAR: "API_TYPEAHEAD_DATA_CLEAR"
};

// Docviz Actions
export const DOCVIZ = {
  GET_DOCVIZ_METADATA: "GET_DOCVIZ_METADATA",
  GET_DOCVIZ_METADATA_MULTI: "GET_DOCVIZ_METADATA_MULTI",
  GET_DOCVIZ_METADATA_PENDING: "GET_DOCVIZ_METADATA_PENDING",
  GET_DOCVIZ_METADATA_SUCCESS: "GET_DOCVIZ_METADATA_SUCCESS",
  UPDATE_DOCVIZ_METADATA_DOC: "UPDATE_DOCVIZ_METADATA_DOC",
  GET_DOCVIZ_METADATA_MULTI_SUCCESS: "GET_DOCVIZ_METADATA_MULTI_SUCCESS",
  GET_DOCVIZ_METADATA_FAILURE: "GET_DOCVIZ_METADATA_FAILURE",
  RESET_DOCVIZ_METADATA: "RESET_DOCVIZ_METADATA",
  DOWNLOAD_CLIPPED_SLIDES_STARTED: "DOWNLOAD_CLIPPED_SLIDES_STARTED",
  CLIPPED_SLIDES_FAILURE: "CLIPPED_SLIDES_FAILURE",
  DOWNLOAD_SELECTED_SLIDES: "DOWNLOAD_SELECTED_SLIDES",
  DOWNLOAD_CLIPPED_SLIDES_FAILED: "DOWNLOAD_CLIPPED_SLIDES_FAILED",
  FETCH_CLIPPED_ID_FAILED: "FETCH_CLIPPED_ID_FAILED",
  GENERATING_CLIPPED_SLIDES: "GENERATING_CLIPPED_SLIDES",
  UPDATE_ONLY_FRAMEWORK_SLIDES: "UPDATE_ONLY_FRAMEWORK_SLIDES",
  DOWNLOAD_CLIPPED_SLIDES_COMPLETE: "DOWNLOAD_CLIPPED_SLIDES_COMPLETE",
  DOWNLOAD_CLIPPED_SLIDES_INPROGRESS: "DOWNLOAD_CLIPPED_SLIDES_INPROGRESS",
  CLIPPED_FILE_STATUS_ERROR: "CLIPPED_FILE_STATUS_ERROR",
  GET_DOCVIZ_METADATA_MULTI_PENDING: "GET_DOCVIZ_METADATA_MULTI_PENDING",
  GET_DOCVIZ_METADATA_MULTI_FAILURE: "GET_DOCVIZ_METADATA_MULTI_FAILURE",
  UPDATE_DOCVIZ_METADATA_MULTI: "UPDATE_DOCVIZ_METADATA_MULTI"
};