import React from "react";
import Image from "components/shared/Image";
import warningiconred from "assets/images/warningiconred.svg";
import PropTypes from 'prop-types';

import "./Answer.scss";

export const AnswerError = ({ error, onRetry }) => {
    return (
        <div className="answerContainer error">
            <Image src={warningiconred} />
            <div>
                <p className="answerText">{error}</p>
            </div>

            <button className="retryButton" onClick={onRetry}>Retry</button>
        </div>
    );
};

AnswerError.propTypes = {
    error: PropTypes.string.isRequired,
    onRetry: PropTypes.string.isRequired,
};