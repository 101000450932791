import React, { useEffect, useRef, useState } from "react";
import "./SourcesFilter.scss";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import SearchIcon from "assets/images/SearchIcon.svg";
import Image from "components/shared/Image";
import {
    RESPONSE_DATA, INFO, TAXONOMY,
    SUCCESS
} from "redux/constants";
import { Checkbox } from '@kdshared/enrich-ui';
import { useDispatch, useSelector } from "react-redux";
import { isInViewport, scrollToTop } from "utils/helpers/helpers";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    SET_FILTERS,
    SET_CURRENT_PAGE,
    SOURCE_FILTER_APPLIED,
    CLEAR_TRANSCRIPT_SELECTED,
    QUERY_RESPONSE
} = RESPONSE_DATA;

const {
    RESET_ALL_SL_PARENT_TAGS
} = TAXONOMY;

export const SourcesFilter = ({ showSourcesFilterContainer, handleFilterIconClick, lastUserQues }) => {

    const dispatch = useDispatch();
    const filters = useSelector(responseSelector.getFilters);
    const queryResponse = useSelector(responseSelector.getRetrievalResult);
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const monthOfInterviewRangeValue = useSelector(responseSelector.getMonthOfInterviewRangeValue);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const selectedSortOption = useSelector(responseSelector.getSelectedSortOption);
    const transcriptResults = queryResponse ? [...queryResponse] : [];
    const actionButtonContainerElementRef = useRef(null);
    let filterRef = useRef();
    const sources = filters.sources;
    const monthOfInterviewRange = filters.monthOfInterviewRange;
    const groupedCompanies = filters.groupedCompanies || [];
    const filterData = filters.filteredIndustryTags || [];
    const filterFunctionTags = filters.filteredFunctionTags || [];
    let sourceList = sources.filter(source => source.selected);
    const pageURL = window.location.href;

    useEffect(() => {
        if (showSourcesFilterContainer && actionButtonContainerElementRef.current) {
            const actionButtonContainer = actionButtonContainerElementRef.current;
            let windowScroll = isInViewport(actionButtonContainer);
            let existingScroll = window.scrollY;
            scrollToTop(existingScroll + windowScroll);
        }
    }, [showSourcesFilterContainer]);

    useEffect(() => {
        let filterHandler = (e) => {
            if (!filterRef.current.contains(e.target) && e.target.className !== 'filter sources')
                handleFilterIconClick(false)
        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })

    const handleClearClick = (e) => {
        const newSourceList = [...sources];
        newSourceList.map(status => {
            status.selected = false
        })
        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });
        handleApplyClick();
    }
    const handleApplyClick = (e) => {
        sourceList = sourceList.filter(source => source.selected);
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            }
        };
        newAdobeData.filterCategory = "Source";
        newAdobeData.filterChecked = sourceList.map(i => i.value).join(" , ");


        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.FILTER_CLICKS);

        handleFilterIconClick(false);
        dispatch({
            type: QUERY_RESPONSE, payload: {
                question: lastUserQues,
                resetFilters: true,
                sort: selectedSortOption,
                currentPage: 0,
                dispatch,
                selectedFilters: {
                    sources: sourceList,
                    monthOfInterview: monthOfInterviewRangeValue.key
                }
            }
        });
        dispatch({ type: SET_CURRENT_PAGE, payload: { response: 0 } });
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        //dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: transcriptResults } });
        dispatch({ type: SOURCE_FILTER_APPLIED, payload: { response: sourceList } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });
        if (companyFilterApplied > 0 || industryFilterApplied > 0 || functionFilterApplied > 0) {
            let notifyMessage = "The existing filters have been cleared.";
            dispatch({ type: INFO, payload: { message: notifyMessage } });
        }
    }
    const handleStatusClick = (event, data) => {
        const checkboxChecked = data.checked;
        const newSourceList = [...sources];
        newSourceList.map(status => {
            if (status.key === data.value)
                status.selected = checkboxChecked
        })

        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });
    };

    return (
        <div ref={filterRef} className="source-filter-container">
            <div className='filter-container'>
                <div className='filter-list-container'>
                    {sources.filter(source => source.show).map((source) => (
                        <div>
                            <div className="filter-item">
                                <Checkbox className="filter-checkbox" label={source.value} value={source.key} checked={source.selected} onClick={handleStatusClick} />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="filter-separator"></div>
                <div className="filter-action-item" ref={actionButtonContainerElementRef}>
                    <button className="btn secondary button" onClick={handleClearClick}>Clear</button>
                    <button className="btn primary button" onClick={handleApplyClick}>Apply Now</button>
                </div>
            </div>
        </div >

    )
};
