import React, { useRef, useState, useEffect } from "react";
import Image from "components/shared/Image";
import { useDispatch, useSelector } from "react-redux";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { QuestionInput } from "../../components/QuestionInput";
import { selectors as docvizSelector } from "../../redux/reducers/docviz/docviz_reducer";
import monthOfInterviewIcon from "assets/images/month_of_interview_icon.svg";
import locationIcon from "assets/images/location_icon.svg";
import durationIcon from "assets/images/duration_icon.svg";
import relevancyIcon from "assets/images/relevancy_icon.svg";
import filterIcon from "assets/images/filterIcon.svg";
import expandDocvizIcon from "assets/images/expand-docviz-icon.svg";
import downIcon from "assets/images/down_icon.svg";
import sortIcon from "assets/images/Sort.svg";
import Modal from "components/shared/Modal";
import noTranscriptSelectedImage from "assets/images/no-transcript-selected.svg";
import Spinner from "assets/images/spinner.gif";
import { getMonthYearFromDate, downloadFileFromUrl, isInViewport } from "utils/helpers/helpers";
import "./SearchResults.scss";
import { RESPONSE_DATA, INFO, DOCVIZ, TAXONOMY } from "redux/constants";
import { TranscriptDetailed } from "../../components/TranscriptDetailed";
import { CompanyFilter } from "../../components/CompanyFilter";
import { SourcesFilter } from "../../components/SourcesFilter";
import { IndustryTags } from "../../components/IndustryTags";
import { FunctionTags } from "../../components/FunctionTags";
import { MonthOfInterviewFilter } from "../../components/MonthOfInterviewFilter";
import { DocvizOverlayView } from "../../components/Docviz/DocvizOverlayView";
import { sendToLocalStorage } from 'utils/localStorage/localStorage';
import CONFIG from "config";
import { Tooltip } from "components/shared/Tooltip";
import Bookmark1Icon from "assets/images/bookmark_1.png";
import Bookmark2Icon from "assets/images/bookmark_2.svg";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    SELECT_TRANSCRIPT,
    GET_DOWNLOAD_COUNT,
    GET_TRANSCRIPT_DETAIL,
    CLEAR_TRANSCRIPT_SELECTED,
    GET_FILE,
    QUERY_RESPONSE,
    SET_CURRENT_PAGE,
    SET_BOOKMARK,
    DELETE_BOOKMARK,
    SET_SORT_STATE,
    MONTH_OF_INTERVIEW_VALUE,
    COMPANY_FILTER_APPLIED,
    SOURCE_FILTER_APPLIED,
    INDUSTRY_FILTER_APPLIED,
    FUNCTION_FILTER_APPLIED,
    RETRIEVAL_API_RESULTS_SUCCESS,
    SET_IS_LAST_PAGE,
    SET_FILTERS
} = RESPONSE_DATA;

const {
    UPDATE_DOCVIZ_METADATA_DOC,
    GET_DOCVIZ_METADATA
} = DOCVIZ;

const {
    RESET_ALL_SL_PARENT_TAGS
} = TAXONOMY;

const {
    SKELETON_COUNT,
    NUM_OF_RESULTS,
    TBDB_IDS: {
        SEARCH_SUBJECT,
        FPA,
        IPA,
        BCG_INTERNAL
    }
} = CONFIG;


export const SearchResults = ({ makeApiRequest, question, isDownloadEnabled = false }) => {
    const dispatch = useDispatch();
    const pageURL = window.location.href;
    const [docvizOpen, setDocvizOpen] = useState(false);
    const doc = useSelector(docvizSelector.getDocvizDocument);
    const listInnerRef = useRef();
    let moreFilterRef = useRef();
    let sortContainerRef = useRef();
    const docvizLoading = useSelector(docvizSelector.getLoading);
    const isResponseLoading = useSelector(responseSelector.getLoading);
    const queryResponse = useSelector(responseSelector.getRetrievalResult);
    const selectedTrancript = useSelector(responseSelector.getSelectedTranscript);
    const retrievalLoading = useSelector(responseSelector.getRetrievalLoading);
    const scrollLoading = useSelector(responseSelector.getScrollLoading);
    const lastUserQues = useSelector(responseSelector.getLatestSearchText);
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const sourceFilterApplied = useSelector(responseSelector.getSourceFilterApplied);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const monthOfInterviewRangeValue = useSelector(responseSelector.getMonthOfInterviewRangeValue);
    const internalTagsAPILoading = useSelector(responseSelector.getInternalTagsAPILoading);
    const currentPage = useSelector(responseSelector.getCurrentPage);
    const isLastPage = useSelector(responseSelector.getIsLastPage);
    const filters = useSelector(responseSelector.getFilters);
    const sources = filters.sources;
    const sortOptions = useSelector(responseSelector.getSortOptions);
    const selectedSortOption = useSelector(responseSelector.getSelectedSortOption);
    const monthOfInterviewRange = filters.monthOfInterviewRange;
    const groupedCompanies = filters.groupedCompanies || [];
    const filteredIndustryTags = filters.filteredIndustryTags || [];
    const filteredFunctionTags = filters.filteredFunctionTags || [];
    const [showCompanyFilterContainer, setShowCompanyFilterContainer] = useState(false);
    const [showSourcesFilterContainer, setShowSourcesFilterContainer] = useState(false);
    const [showMoreFilterContainer, setShowMoreFilterContainer] = useState(false);
    const [showSortContainer, setShowSortContainer] = useState(false);
    const [showIndustryFilterContainer, setShowIndustryFilterContainer] = useState(false);
    const [showFunctionFilterContainer, setShowFunctionFilterContainer] = useState(false);
    const [showMonthOfInterviewFilterContainer, setShowMonthOfInterviewFilterContainer] = useState(false);
    const [showClearAllFiltersConfirmation, setShowClearAllFiltersConfirmation] = useState(false);
    const transcriptResults = queryResponse ? queryResponse : [];
    const skeltonTranscripts = SKELETON_COUNT;
    const companyList = [];
    groupedCompanies.map(group => {
        group.children.map(status => {
            if (status.selected) {
                companyList.push(status.key.substring(0, status.key.lastIndexOf(" (")));
            }
        })
    })

    useEffect(() => {
        let filterHandler = (e) => {
            if ((!showFunctionFilterContainer && !showIndustryFilterContainer) && moreFilterRef.current && !moreFilterRef.current.contains(e.target) && e.target.className !== 'filter more-filters') {
                handleMoreFilterIconClick(false)
            }
            if (sortContainerRef.current && !sortContainerRef.current.contains(e.target) && e.target.className !== 'sort') {
                handleSortIconClick(false)
            }
        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })
    const industryTagsList = [];
    filteredIndustryTags.map(it => {
        if (it.checked) {
            industryTagsList.push(it.tagName);
        }
        it.children && it.children.map(it1 => {
            if (it1.checked) {
                industryTagsList.push(it1.tagName);
            }
            it1.children && it1.children.map(it2 => {
                if (it2.checked) {
                    industryTagsList.push(it2.tagName);
                }
            })
        })
    })

    const functionTagsList = [];
    filteredFunctionTags.map(it => {
        if (it.checked) {
            functionTagsList.push(it.tagName);
        }
        it.children && it.children.map(it1 => {
            if (it1.checked) {
                functionTagsList.push(it1.tagName);
            }
            it1.children && it1.children.map(it2 => {
                if (it2.checked) {
                    functionTagsList.push(it2.tagName);
                }
            })
        })
    });

    const isFilterApplied = companyFilterApplied > 0 || industryFilterApplied > 0 || functionFilterApplied > 0;
    const isAllFilterOrSortingApplied = isFilterApplied
        || monthOfInterviewRangeValue.key !== ''
        || sourceFilterApplied.length > 0
        || selectedSortOption !== sortOptions[0];

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (!isFilterApplied && !isLastPage && !retrievalLoading && scrollTop + clientHeight > scrollHeight - 20) {
                //setScrollLoading(true); 
                dispatch({
                    type: QUERY_RESPONSE, payload: {
                        question: lastUserQues,
                        resetFilters: true,
                        sort: selectedSortOption,
                        currentPage: currentPage + 1,
                        transcriptResults,
                        dispatch,
                        selectedFilters: {
                            sources: sourceFilterApplied,
                            monthOfInterview: monthOfInterviewRangeValue.key
                        }
                    }
                });
                dispatch({
                    type: SET_CURRENT_PAGE, payload: {
                        response: currentPage + 1
                    }
                });
            }
        }
    };

    const handleClearFilterIconClick = () => {


        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: [] } });
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: SET_CURRENT_PAGE, payload: { response: 0 } });
        dispatch({ type: SET_SORT_STATE, payload: { response: "Most Relevant" } });
        dispatch({ type: SET_IS_LAST_PAGE, payload: { response: false } });
        dispatch({
            type: QUERY_RESPONSE, payload: {
                question, resetFilters: true, currentPage: 0, dispatch, pageReload: false
            }
        });

        dispatch({ type: MONTH_OF_INTERVIEW_VALUE, payload: { response: CONFIG.DATE_FILTER_OPTIONS[0] } });
        dispatch({ type: COMPANY_FILTER_APPLIED, payload: { response: 0 } });
        dispatch({ type: SOURCE_FILTER_APPLIED, payload: { response: [] } });
        dispatch({ type: INDUSTRY_FILTER_APPLIED, payload: { response: 0 } });
        dispatch({ type: FUNCTION_FILTER_APPLIED, payload: { response: 0 } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });
        const newSourceList = [...sources];
        newSourceList.map(status => {
            status.selected = false
        })
        dispatch({ type: SET_FILTERS, payload: { sources: newSourceList } });
        dispatch({
            type: SET_FILTERS, payload: {
                response: {
                    monthOfInterviewRange: {
                        startMonth: null,
                        endMonth: null
                    }
                }
            }
        });
        setShowClearAllFiltersConfirmation(false);
    }

    const handleSelectedSortClick = (so) => {
        if (so !== selectedSortOption) {
            dispatch({
                type: SET_SORT_STATE, payload: {
                    response: so
                }
            });
            handleSortIconClick(false);
            dispatch({
                type: QUERY_RESPONSE, payload: {
                    question,
                    resetFilters: true,
                    sort: so,
                    currentPage: 0,
                    dispatch,
                    selectedFilters: {
                        sources: sourceFilterApplied,
                        monthOfInterview: monthOfInterviewRangeValue.key
                    }
                }
            });
            dispatch({ type: SET_CURRENT_PAGE, payload: { response: 0 } });
            dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
            if (isFilterApplied) {
                let notifyMessage = "The existing filters have been cleared.";
                dispatch({ type: INFO, payload: { title: "INFO", message: notifyMessage } });
            }
        }
    }
    const transcriptClicked = (transcript, trackEvent) => {
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            },
            link: { toolSearch: transcript.meeting_id },
            filterCategory: "",
            filterChecked: ""
        };
        if (companyFilterApplied > 0) {
            newAdobeData.filterCategory += "Company Name | ";
            newAdobeData.filterChecked += companyList.join(" , ") + " | ";
        }
        if (monthOfInterviewRangeValue.key !== '') {
            newAdobeData.filterCategory += "Month of Interview | ";
            newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
        }
        if (sourceFilterApplied.length > 0) {
            newAdobeData.filterCategory += "Source | ";
            newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
        }
        if (industryFilterApplied > 0) {
            newAdobeData.filterCategory += "IndustryPA | ";
            newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
        }
        if (functionFilterApplied > 0) {
            newAdobeData.filterCategory += "FunctionPA | ";
            newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
        }
        if (trackEvent || transcript.meeting_id !== selectedTrancript.meeting_id) {
            ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.TOOLS_SEARCH_LINK_CLICKS);
        }
        transcript.docvizRequestId = transcript.docvizRequestId ? transcript.docvizRequestId : undefined;
        dispatch({ type: SELECT_TRANSCRIPT, payload: { response: transcript } });
        dispatch({ type: GET_DOWNLOAD_COUNT, payload: { transcriptId: transcript.meeting_id } });
        dispatch({ type: GET_TRANSCRIPT_DETAIL, payload: { transcriptId: transcript.meeting_id } });
        dispatch({ type: UPDATE_DOCVIZ_METADATA_DOC, payload: { response: { fileName: transcript.sanitized_attachment_name ? transcript.sanitized_attachment_name.replace(/(\r\n|\n|\r)/gm, "") + ".docx" : "", doc: "" } } });
        if (transcript.industryTags?.length > 0) {
            dispatch({
                type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: {
                    ids: transcript.industryTags, key: IPA
                }
            })
        }
        if (transcript.functionalTags?.length > 0) {
            dispatch({
                type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: {
                    ids: transcript.functionalTags, key: FPA
                }
            })
        }
    };
    useEffect(() => {
        if (selectedTrancript === '' && transcriptResults.length > 0) {
            const transcripts = transcriptResults.filter(transcript => !transcript.isHide && !transcript.isHideCF && !transcript.isHideSF && !transcript.isHideIF && !transcript.isHideFF);
            if (transcripts.length > 0) {
                let transcript = transcripts[0];
                dispatch({ type: SELECT_TRANSCRIPT, payload: { response: transcript } });
                dispatch({ type: GET_DOWNLOAD_COUNT, payload: { transcriptId: transcript.meeting_id } });
                dispatch({ type: GET_TRANSCRIPT_DETAIL, payload: { transcriptId: transcript.meeting_id } });
                dispatch({ type: UPDATE_DOCVIZ_METADATA_DOC, payload: { response: { fileName: transcript.sanitized_attachment_name ? transcript.sanitized_attachment_name.replace(/(\r\n|\n|\r)/gm, "") + ".docx" : "", doc: "" } } });
                if (transcript.industryTags?.length > 0) {
                    dispatch({
                        type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: {
                            ids: transcript.industryTags, key: IPA
                        }
                    })
                }
                if (transcript.functionalTags?.length > 0) {
                    dispatch({
                        type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: {
                            ids: transcript.functionalTags, key: FPA
                        }
                    })
                }
            }

        }
    }, [transcriptResults]);

    const downloadFile = (transcript, trackClick) => {

        if (trackClick) {
            const newAdobeData = {
                page: {
                    category: {
                        primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                    },
                    pageInfo: {
                        pageName: window.location.pathname === '/' ? PAGES.Transcript_Library_Keyword_Search : PAGES.Detailed_Transcript_View,
                        previousPageName: window.location.pathname === '/' ? PAGES.Transcript_Library_Keyword_Search : PAGES.Detailed_Transcript_View,
                        pageURL: pageURL
                    }
                },
                internal: {
                    search: { term: lastUserQues }
                },
                iconName: "Download",
                link: { toolSearch: transcript.meeting_id },
                filterCategory: "",
                filterChecked: ""
            };
            const addFilterData = (category, checked, list) => {
                newAdobeData.filterCategory += `${category} | `;
                newAdobeData.filterChecked += `${list.join(" , ")} | `;
            };

            if (companyFilterApplied > 0) addFilterData("Company Name", companyFilterApplied, companyList);
            if (monthOfInterviewRangeValue.key !== '') addFilterData("Month of Interview", monthOfInterviewRangeValue, [monthOfInterviewRangeValue]);
            if (sourceFilterApplied.length > 0) addFilterData("Source", sourceFilterApplied.length, sourceFilterApplied.map(i => i.key));
            if (industryFilterApplied > 0) addFilterData("IndustryPA", industryFilterApplied, industryTagsList);
            if (functionFilterApplied > 0) addFilterData("FunctionPA", functionFilterApplied, functionTagsList);

            ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.ICON_ACTION_CLICKS);
        }

        if (!transcript.kpId) {
            transcript.kpId = transcript.meeting_id;
            transcript.fileName = transcript.sanitized_attachment_name.replace(/(\r\n|\n|\r)/gm, "") + ".docx";
        }

        dispatch({ type: GET_FILE, payload: { transcript } });
    };

    const openTranscriptInNewTab = (event, transcript) => {
        event.stopPropagation();
        sendToLocalStorage("eVar2ForDetailedTranscript", "fromKeywordSearch");
        downloadFileFromUrl(`transcript/${transcript.meeting_id}`, "_blank");
        transcriptClicked(transcript, true);
    };

    const openDocvizComponent = (transcript, event) => {
        if (transcript.docvizConversionStatus !== "COMPLETED" || transcript.docvizRequestId === undefined)
            return
        event.stopPropagation();

        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            },
            link: { toolSearch: transcript.meeting_id },
            filterCategory: "",
            filterChecked: ""
        };
        if (companyFilterApplied > 0) {
            newAdobeData.filterCategory += "Company Name | ";
            newAdobeData.filterChecked += companyList.join(" , ") + " | ";
        }
        if (monthOfInterviewRangeValue.key !== '') {
            newAdobeData.filterCategory += "Month of Interview | ";
            newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
        }
        if (sourceFilterApplied.length > 0) {
            newAdobeData.filterCategory += "Source | ";
            newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
        }
        if (industryFilterApplied > 0) {
            newAdobeData.filterCategory += "IndustryPA | ";
            newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
        }
        if (functionFilterApplied > 0) {
            newAdobeData.filterCategory += "FunctionPA | ";
            newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
        }
        if (transcript.meeting_id !== selectedTrancript.meeting_id) {
            //ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.TOOLS_SEARCH_LINK_CLICKS);
        }

        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.PREVIEW_DOCUMENT);

        setDocvizOpen(true);
        transcriptClicked(transcript);
        //dispatch({ type: GET_DOCVIZ_METADATA, payload: { id: transcript.docvizRequestId, meeting_id: transcript.meeting_id } });
        //downloadFileFromUrl(`transcript/${transcript.meeting_id}`, "_blank")
    };

    const handleCompanyFilterIconClick = (status) => {
        status === false ? setShowCompanyFilterContainer(status) : setShowCompanyFilterContainer(!showCompanyFilterContainer);
    }

    const handleSourcesFilterIconClick = (status) => {
        status === false ? setShowSourcesFilterContainer(status) : setShowSourcesFilterContainer(!showSourcesFilterContainer);
    }

    const handleMoreFilterIconClick = (status) => {
        status === false ? setShowMoreFilterContainer(status) : setShowMoreFilterContainer(!showMoreFilterContainer);
    }
    const handleSortIconClick = (status) => {
        status === false ? setShowSortContainer(status) : setShowSortContainer(!showMoreFilterContainer);
    }

    const handleIndustryFilterIconClick = (status) => {
        if (status === false) {
            setShowIndustryFilterContainer(status);
            setShowMoreFilterContainer(status);
        } else {
            setShowIndustryFilterContainer(!showIndustryFilterContainer);
        }
    }

    const handleFunctionFilterIconClick = (status) => {
        if (status === false) {
            setShowFunctionFilterContainer(status);
            setShowMoreFilterContainer(status);
        } else {
            setShowFunctionFilterContainer(!showFunctionFilterContainer);
        }
    }

    const bookmarkFile = (event, transcript) => {
        event.stopPropagation();
        dispatch({ type: SET_BOOKMARK, payload: { transcript, selectedTrancript } });
    }
    const unBookmarkFile = (event, transcript) => {
        event.stopPropagation();
        dispatch({ type: DELETE_BOOKMARK, payload: { transcript, selectedTrancript } });
    }
    const handleMonthOfInterviewFilterIconClick = (status) => {
        status === false ? setShowMonthOfInterviewFilterContainer(status) : setShowMonthOfInterviewFilterContainer(!showMonthOfInterviewFilterContainer);
    }
    return (
        <div className={"searchResults"}>
            <div className={"searchResults__filter-container"}>
                <div className={"chatInput"}>
                    <QuestionInput
                        questionInput={question}
                        placeholder="Search for keywords or companies"
                        disabled={isResponseLoading}
                        onSend={question => makeApiRequest(question, undefined, true)}
                    />
                </div>
                <div className="filter-box">
                    {(scrollLoading || !retrievalLoading) &&
                        <>
                            <div className={"filter-parent"}>
                                <div className={"numberOfSeacrhResults"}>
                                    <span>{!scrollLoading && retrievalLoading ? "" : transcriptResults.filter(transcript => !transcript.isHide && !transcript.isHideCF && !transcript.isHideSF && !transcript.isHideIF && !transcript.isHideFF).length + " Search Results"}</span>
                                </div>
                                <Image src={filterIcon} alt="logo" />
                                {(!retrievalLoading) &&
                                    <span className={`filter company ${(internalTagsAPILoading || transcriptResults.length === 0) && 'disabled'}`} onClick={() => !(internalTagsAPILoading || transcriptResults.length === 0) && handleCompanyFilterIconClick()}>Company name {companyFilterApplied > 0 && (`(${companyFilterApplied})`)}
                                        <Image src={downIcon} alt="DownIcon" className={`${showCompanyFilterContainer ? 'rotate' : ''}`} /></span>}
                                {showCompanyFilterContainer &&
                                    <CompanyFilter
                                        lastUserQues={lastUserQues}
                                        companyList={companyList}
                                        showCompanyFilterContainer={showCompanyFilterContainer}
                                        handleFilterIconClick={handleCompanyFilterIconClick} />
                                }
                                {(!retrievalLoading) &&
                                    <span className={`filter date ${internalTagsAPILoading && 'disabled'}`} onClick={() => !internalTagsAPILoading && handleMonthOfInterviewFilterIconClick()}>
                                        <Image src={monthOfInterviewIcon} alt="logo" />
                                        {monthOfInterviewRangeValue.key === '' ? 'Select Date' : monthOfInterviewRangeValue.value}
                                        <Image src={downIcon} alt="DownIcon" className={`${showMonthOfInterviewFilterContainer ? 'rotate downIcon' : 'downIcon'}`} />
                                    </span>
                                }
                                {showMonthOfInterviewFilterContainer &&
                                    <MonthOfInterviewFilter
                                        lastUserQues={lastUserQues}
                                        monthOfInterviewRangeValue={monthOfInterviewRangeValue}
                                        showMonthOfInterviewFilterContainer={showMonthOfInterviewFilterContainer} handleFilterIconClick={handleMonthOfInterviewFilterIconClick} />
                                }

                                {(!retrievalLoading) &&
                                    <span className={`filter sources ${internalTagsAPILoading && 'disabled'}`} onClick={() => !internalTagsAPILoading && handleSourcesFilterIconClick()}>Source {sourceFilterApplied.length > 0 && (`(${sourceFilterApplied.length})`)}
                                        <Image src={downIcon} alt="DownIcon" className={`${showSourcesFilterContainer ? 'rotate' : ''}`} /></span>}
                                {showSourcesFilterContainer &&
                                    <SourcesFilter
                                        lastUserQues={lastUserQues}
                                        showSourcesFilterContainer={showSourcesFilterContainer}
                                        handleFilterIconClick={handleSourcesFilterIconClick} />
                                }

                                {(!retrievalLoading) &&
                                    <span className={`filter more-filters ${(internalTagsAPILoading || transcriptResults.length === 0) && 'disabled'}`} onClick={() => !(internalTagsAPILoading || transcriptResults.length === 0) && handleMoreFilterIconClick()}>Practice Area {(industryFilterApplied > 0 || functionFilterApplied > 0) && (`(${industryFilterApplied + functionFilterApplied})`)}
                                        <Image src={downIcon} alt="DownIcon" className={`${showMoreFilterContainer ? 'rotate' : ''}`} /></span>}
                                {showMoreFilterContainer &&
                                    <div ref={moreFilterRef} className="more-filter-container">
                                        <div className='more-filter'>
                                            <div className='more-filter-list-container'>
                                                <div className="filter-item">
                                                    {filteredIndustryTags.length > 0 &&
                                                        <span className="" onClick={() => handleIndustryFilterIconClick()}>Industry PA {industryFilterApplied > 0 && (`(${industryFilterApplied})`)}</span>}
                                                    {showIndustryFilterContainer &&
                                                        <IndustryTags
                                                            lastUserQues={lastUserQues}
                                                            showSourcesFilterContainer={showIndustryFilterContainer}
                                                            handleFilterIconClick={handleIndustryFilterIconClick} />
                                                    }
                                                </div>
                                                <div className="filter-item">
                                                    {(transcriptResults.length > 0) && filteredFunctionTags.length > 0 &&
                                                        <span className="" onClick={() => handleFunctionFilterIconClick()}>Function PA {functionFilterApplied > 0 && (`(${functionFilterApplied})`)}</span>}
                                                    {showFunctionFilterContainer &&
                                                        <FunctionTags
                                                            lastUserQues={lastUserQues}
                                                            showSourcesFilterContainer={showFunctionFilterContainer}
                                                            handleFilterIconClick={handleFunctionFilterIconClick} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                }
                                {(!retrievalLoading) &&
                                    <>
                                        <span data-tip data-for={`clear-filter-tooltip`} className={`delete-all-text ${!isAllFilterOrSortingApplied ? 'disabled' : ''}`} onClick={() => isAllFilterOrSortingApplied && setShowClearAllFiltersConfirmation(true)}>
                                            Clear Filters
                                        </span>
                                        {!isAllFilterOrSortingApplied && <Tooltip id={`clear-filter-tooltip`} delayHide={0}>Please select any filter or apply sorting</Tooltip>}
                                    </>
                                }

                                <Modal
                                    isOpen={showClearAllFiltersConfirmation}
                                    className="clear_all__modal">
                                    <div className="clear_all__modal-content">
                                        <h2>Clear Filters and Sorting</h2>
                                        <p>This operation will clear all the selected Filters and Sorting</p>
                                        <div className="modal-buttons">
                                            <button className="cancel-button" onClick={() => setShowClearAllFiltersConfirmation(false)}>Cancel</button>
                                            <button className="delete-button" onClick={() => handleClearFilterIconClick()}>Clear</button>
                                        </div>
                                    </div>
                                </Modal>

                            </div>
                            <div className="sort-parent">
                                {(!retrievalLoading) &&
                                    <>
                                        <Image src={sortIcon} alt="sortIcon" className="sort-icon" />
                                        <span className={`sort ${(internalTagsAPILoading || transcriptResults.length === 0) && 'disabled'}`} onClick={() => !(internalTagsAPILoading || transcriptResults.length === 0) && handleSortIconClick()}>{selectedSortOption}
                                            <Image src={downIcon} alt="DownIcon" className={`${showSortContainer ? 'rotate' : ''}`} /></span>
                                    </>}
                                {showSortContainer &&
                                    <div ref={sortContainerRef} className="sort-container">
                                        <div className='sort-1'>
                                            <div className='sort-list-container'>
                                                {sortOptions.map(so => (
                                                    <div key={so} className={`filter-item ${so === selectedSortOption && "selected"}`}>
                                                        <span className="" onClick={() => handleSelectedSortClick(so)}>{so}</span>
                                                    </div>))
                                                }
                                            </div>
                                        </div>
                                    </div >
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className={"searchResults__transcripts"}>
                {docvizOpen && !docvizLoading && doc.baseURL &&
                    <DocvizOverlayView
                        doc={doc}
                        downloadClicked={downloadFile}
                        setDocvizOpen={setDocvizOpen}
                        transcript={selectedTrancript}
                        isDownloadEnabled={isDownloadEnabled}
                    />}
                <div onScroll={onScroll}
                    ref={listInnerRef} className={`list-view ${(!retrievalLoading && transcriptResults.filter(transcript => !transcript.isHide && !transcript.isHideCF && !transcript.isHideSF && !transcript.isHideIF && !transcript.isHideFF).length === 0) ? 'no-transcript-available' : ''}`}>
                    {(!scrollLoading && retrievalLoading) ? skeltonTranscripts.map((transcript) => (
                        <div key={transcript} className="single-transcript">

                            <div className="single-transcript__details">
                                <div className="single-transcript__generated-title skeleton">
                                    <Skeleton count={2} />
                                </div>
                                <div className="single-transcript__meta-data">
                                    <Skeleton />
                                </div>
                                <div className="single-transcript__generated-summary">
                                    <div className="heading"></div>
                                    <div className="summary">
                                        <Skeleton count={2} />
                                    </div>
                                </div>
                            </div>
                        </div>)) :
                        (<>
                            {transcriptResults.map((transcript) => {
                                const isSelected = transcript.document_Id === selectedTrancript.document_Id;
                                if (transcript.isHide || transcript.isHideCF || transcript.isHideSF || transcript.isHideIF || transcript.isHideFF) {
                                    return null;
                                };
                                return (
                                    <div key={transcript.doc_id} className={`single-transcript ${isSelected && "isSelected"}`} onClick={() => transcriptClicked(transcript)}>
                                        <div className="single-transcript__details">
                                            <div className="single-transcript__generated-title" onClick={(e) => openTranscriptInNewTab(e, transcript)}>
                                                <div className="generated-title"><span>{transcript.generated_title}</span></div>
                                                <div className="relevancy-score">
                                                    <span data-tip data-for={`relevancy-score-${transcript.meeting_id}`}>{Math.round(transcript._score * 10) + "%"}</span>
                                                </div>
                                                <Tooltip id={`relevancy-score-${transcript.meeting_id}`} delayHide={0}>Relevancy Score</Tooltip>
                                                <div className="bookmark-tooltip">

                                                    {
                                                        transcript.bookmarked === false &&
                                                        <>
                                                            <Image className="bookmark-icon" data-tip data-for={`SetBookmark${transcript.meeting_id}`} src={Bookmark1Icon} alt="logo" onClick={(e) => bookmarkFile(e, transcript)} />
                                                            <Tooltip id={`SetBookmark${transcript.meeting_id}`} delayHide={0}>Bookmark</Tooltip>
                                                        </>
                                                    }
                                                    {
                                                        transcript.bookmarked === true &&
                                                        <>
                                                            <Image className="bookmark-icon" data-tip data-for={`DeleteBookmark1${transcript.meeting_id}`} src={Bookmark2Icon} alt="logo" onClick={(e) => unBookmarkFile(e, transcript)} />
                                                            <Tooltip id={`DeleteBookmark1${transcript.meeting_id}`} delayHide={0}>Delete Bookmark</Tooltip>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="single-transcript__meta-data">
                                                {transcript.vendor_source != "Tegus" && <div className="meta-data 1">
                                                    <Image src={locationIcon} alt="logo" />
                                                    <span>{transcript.expert_region && transcript.expert_region !== "null" ? transcript.expert_region : "-"} | {transcript.duration_of_interview && transcript.duration_of_interview !== "null" ? transcript.duration_of_interview + " mins" : "-"}</span>
                                                </div>}
                                                <div className="meta-data 2">
                                                    <Image src={durationIcon} alt="logo" />
                                                    <span>{getMonthYearFromDate(new Date(transcript.date_of_interview * 1000))}</span>
                                                </div>
                                                <div className="meta-data 3">
                                                    <span className="source-pill">{transcript.vendor_source}</span>
                                                    {
                                                        transcript.vendor_source !== "Tegus" &&
                                                        <>
                                                            <span data-tip data-for={`bcg-pill`} className={`source-pill ${transcript.vendor_source === "Tegus" ? "non-bcg" : "bcg"}`}>
                                                                {transcript.vendor_source === "Tegus" ? "Non-BCG" : "BCG"}
                                                            </span>
                                                            <Tooltip id="bcg-pill" delayHide={0}>Calls Conducted by BCG</Tooltip>
                                                        </>
                                                    }
                                                    {/* <Image src={relevancyIcon} alt="logo" />
                                                <span>Relevancy | {Math.round(transcript.relevance * 100)}%</span> */}
                                                </div>
                                            </div>
                                            <div className="single-transcript__generated-summary">
                                                <div className="heading"></div>
                                                <div className="summary">
                                                    <p>
                                                        {transcript.generated_summary}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {(scrollLoading) && <div className="loading-more-data">Loading more data ....</div>}
                            {(isLastPage && !isFilterApplied) && <div className="loading-more-data">No more transcripts to display</div>}
                        </>)
                    }

                </div>
                <div className={"detalied-view"}>
                    {(selectedTrancript === '' || selectedTrancript.document_Id === undefined) ?
                        (<div className="no-transcript-selected">
                            {retrievalLoading ?
                                <div className="skelton-wrapper">
                                    {/* <Skeleton height={200} /> */}
                                    <Image src={Spinner} alt="Spinner" />
                                </div> : <>
                                    <Image src={noTranscriptSelectedImage} alt="logo" />
                                    <div className="no-transcript-selected__text">
                                        <p>No records found </p>
                                        {transcriptResults.filter(transcript => !transcript.isHide && !transcript.isHideCF && !transcript.isHideSF && !transcript.isHideIF && !transcript.isHideFF).length !== 0 && <p>Select a transcript from list</p>}
                                    </div>
                                </>}
                        </div>) : (
                            <TranscriptDetailed
                                transcript={selectedTrancript}
                                lastUserQues={lastUserQues}
                                companyFilterApplied={companyFilterApplied}
                                monthOfInterviewRangeValue={monthOfInterviewRangeValue}
                                companyList={companyList}
                                isDownloadEnabled={isDownloadEnabled}
                                sourceFilterApplied={sourceFilterApplied}
                                industryFilterApplied={industryFilterApplied}
                                functionFilterApplied={functionFilterApplied}
                                industryTagsList={industryTagsList}
                                functionTagsList={functionTagsList}
                            >
                            </TranscriptDetailed>
                        )
                    }
                </div>
            </div>
        </div >
    );
};
