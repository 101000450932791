import React, { useEffect } from "react";
import "./DocvizOverlayView.scss";
import { useDispatch, useSelector } from "react-redux";
import { CardView, CardViewSubject, GlobalStyle, OverlayView, OverlayViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
import authService from 'redux/auth';
import { useState } from "react";
import { RESPONSE_DATA, ROLES } from "redux/constants";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { DOCVIZ } from "redux/constants";
import CONFIG from "config";
// Analytics
import ANALYTICS from "utils/analytics/analytics";
import { doAxiosRequest } from "config-axios";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

export const DocvizOverlayView = ({ doc, actions = {
    showPreview: true,
    showEyePreview: false,
    showDownload: true,
    showShare: false,
    showEmail: false
}, apiConfig = {
    key: process.env.REACT_APP_TL_ENRICH_API_KEY, // used to fetch a document
    token: () => authService.getAccessToken(), // an async function returning the user's bearer token, just the token, less 'Bearer'
    env: "" // the prefix for links, prepends to `${env}.bcg.com/xxx`
},
    setDocvizOpen,
    transcript,
    downloadClicked,
    isDownloadEnabled
}) => {
    const pageURL = window.location.href;
    const lastUserQues = useSelector(responseSelector.getLatestSearchText);
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const monthOfInterviewRangeValue = useSelector(responseSelector.getMonthOfInterviewRangeValue);
    const filters = useSelector(responseSelector.getFilters);
    const groupedCompanies = filters.groupedCompanies || [];
    const pathname = window.location.pathname;
    const companyList = [];
    const sourceFilterApplied = useSelector(responseSelector.getSourceFilterApplied);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const filteredIndustryTags = filters.filteredIndustryTags || [];
    const filteredFunctionTags = filters.filteredFunctionTags || [];
    const industryTagsList = [];
    filteredIndustryTags.map(it => {
        if (it.checked) {
            industryTagsList.push(it.tagName);
        }
        it.children && it.children.map(it1 => {
            if (it1.checked) {
                industryTagsList.push(it1.tagName);
            }
            it1.children && it1.children.map(it2 => {
                if (it2.checked) {
                    industryTagsList.push(it2.tagName);
                }
            })
        })
    })

    const functionTagsList = [];
    filteredFunctionTags.map(it => {
        if (it.checked) {
            functionTagsList.push(it.tagName);
        }
        it.children && it.children.map(it1 => {
            if (it1.checked) {
                functionTagsList.push(it1.tagName);
            }
            it1.children && it1.children.map(it2 => {
                if (it2.checked) {
                    functionTagsList.push(it2.tagName);
                }
            })
        })
    })
    groupedCompanies.map(group => {
        group.children.map(status => {
            if (status.selected) {
                companyList.push(status.key.substring(0, status.key.lastIndexOf(" (")));
            }
        })
    })
    const dispatch = useDispatch();

    let overlaySubscriber;
    useEffect(() => {
        overlaySubscriber = OverlayViewSubject.subscribe(action => {
            const pageName = pathname === '/' ? PAGES.Transcript_Library_Keyword_Search : pathname.includes('chat') ? PAGES.Transcript_Library_Chatbot : PAGES.Detailed_Transcript_View;
            let newAdobeData;
            switch (action.type) {
                case SUBSCRIBERS.CLOSE_CLICK:
                    setDocvizOpen(false);
                    newAdobeData = {
                        page: {
                            category: {
                                primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                            },
                            pageInfo: {
                                pageName: pageName,
                                previousPageName: pageName,
                                pageURL: pageURL
                            }
                        },
                        internal: {
                            search: { term: lastUserQues }
                        },
                        link: { toolSearch: doc.kpId },
                        filterCategory: "",
                        filterChecked: ""
                    };
                    if (companyFilterApplied > 0) {
                        newAdobeData.filterCategory += "Company Name | ";
                        newAdobeData.filterChecked += companyList.join(" , ") + " | ";
                    }
                    if (monthOfInterviewRangeValue.key !== '') {
                        newAdobeData.filterCategory += "Month of Interview | ";
                        newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
                    }
                    if (sourceFilterApplied.length > 0) {
                        newAdobeData.filterCategory += "Source | ";
                        newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
                    }
                    if (industryFilterApplied > 0) {
                        newAdobeData.filterCategory += "IndustryPA | ";
                        newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
                    }
                    if (functionFilterApplied > 0) {
                        newAdobeData.filterCategory += "FunctionPA | ";
                        newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
                    }
                    ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.CLOSE_DOCUMENT);
                    break;
                case SUBSCRIBERS.CLIP_DOWNLOAD_CLICK:
                    if (isDownloadEnabled) {
                        const { slides } = action;
                        const slideNumbers = slides.map(s => s.slideNumber);
                        dispatch({
                            type: DOCVIZ.DOWNLOAD_SELECTED_SLIDES,
                            payload: {
                                pages: slideNumbers,
                                documentId: doc.kpId,
                                doc
                            }
                        })
                        newAdobeData = {
                            page: {
                                category: {
                                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                                },
                                pageInfo: {
                                    pageName: pageName,
                                    previousPageName: pageName,
                                    pageURL: pageURL
                                }
                            },
                            internal: {
                                search: { term: lastUserQues }
                            },
                            iconName: "Download",
                            checkBoxName: slideNumbers,
                            link: { toolSearch: doc.kpId },
                            filterCategory: "",
                            filterChecked: ""
                        };
                        if (companyFilterApplied > 0) {
                            newAdobeData.filterCategory += "Company Name | ";
                            newAdobeData.filterChecked += companyList.join(" , ") + " | ";
                        }
                        if (monthOfInterviewRangeValue.key !== '') {
                            newAdobeData.filterCategory += "Month of Interview | ";
                            newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
                        }
                        if (sourceFilterApplied.length > 0) {
                            newAdobeData.filterCategory += "Source | ";
                            newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
                        }
                        if (industryFilterApplied > 0) {
                            newAdobeData.filterCategory += "IndustryPA | ";
                            newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
                        }
                        if (functionFilterApplied > 0) {
                            newAdobeData.filterCategory += "FunctionPA | ";
                            newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
                        }
                        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.ICON_ACTION_CLICKS);
                    }
                    break;
                case SUBSCRIBERS.OVERLAY_DOWNLOAD_CLICK:
                    if (isDownloadEnabled) {
                        downloadClicked(action.doc, false);
                        newAdobeData = {
                            page: {
                                category: {
                                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                                },
                                pageInfo: {
                                    pageName: pageName,
                                    previousPageName: pageName,
                                    pageURL: pageURL
                                }
                            },
                            internal: {
                                search: { term: lastUserQues }
                            },
                            iconName: "Download",
                            link: { toolSearch: doc.kpId },
                            filterCategory: "",
                            filterChecked: ""
                        };
                        if (companyFilterApplied > 0) {
                            newAdobeData.filterCategory += "Company Name | ";
                            newAdobeData.filterChecked += companyList.join(" , ") + " | ";
                        }
                        if (monthOfInterviewRangeValue.key !== '') {
                            newAdobeData.filterCategory += "Month of Interview | ";
                            newAdobeData.filterChecked += monthOfInterviewRangeValue + " | ";
                        }
                        if (sourceFilterApplied.length > 0) {
                            newAdobeData.filterCategory += "Source | ";
                            newAdobeData.filterChecked += sourceFilterApplied.map(i => i.key).join(" , ") + " | ";
                        }
                        if (industryFilterApplied > 0) {
                            newAdobeData.filterCategory += "IndustryPA | ";
                            newAdobeData.filterChecked += industryTagsList.join(" , ") + " | ";
                        }
                        if (functionFilterApplied > 0) {
                            newAdobeData.filterCategory += "FunctionPA | ";
                            newAdobeData.filterChecked += functionTagsList.join(" , ") + " | ";
                        }
                        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.ICON_ACTION_CLICKS);
                    }
                    break;
                default:
                    break;
            }
        });

        return () => {
            if (overlaySubscriber) {
                overlaySubscriber.unsubscribe();
            }
        }
    })
    const genaiSlideSearch = async (query, document) => { // query is the user's input, document is the doc object you sent DocViz
        const { API_URL: { RETRIVAL_API }, API_KEYS: { SMP_API_KEY } } = CONFIG;
        const request_body = {
            'query': `"${query}"`, // the user's query, in quotes
            "method": "bm25",
            "top_n": 200,
            // "top_vector_per_doc": true,
            "consumer_options": {
                "consumer_key": "EET",
                "vector_store_url_param": "eet",
                "data_source": "eet"
            },
            "filters": [
                {
                    "name": "meeting_id",
                    "type": "list",
                    "filter_values": [
                        document.kpId
                    ]
                }
            ]
        };

        const retrievalData = await doAxiosRequest({
            method: 'post',
            endpoint: RETRIVAL_API,
            headers: {
                'x-api-key': SMP_API_KEY
            },
            params: request_body
        })
        const augmentedData = {  // we need to transform the returned data to what DocViz is expecting, exact mapping here will depend on your document object and its parameters
            'resultCount': retrievalData.results.length,
            'attachments': {
                'guid': document.kpId,
                'restrictedFlag': 'false',
                'previewAvailable': document.previewAvailable,
                'attachmentSlideCount': document.slides.length,
                'relevantSlides': retrievalData.results.map(slide => {
                    return { 'relevance': slide.relevance, 'slideNumber': slide.page };
                }).sort((a, b) => a.slideNumber - b.slideNumber),
                'url': '',
                's3Object': document.s3Object,
                'previews': document.previews
            }
        };
        return augmentedData;
    };
    return (
        <div className="overlayview-wrapper">
            <OverlayView
                className={!isDownloadEnabled && 'download-disabled'}
                doc={doc}
                apiConfig={apiConfig}
                actions={actions}
                slideSearch={genaiSlideSearch}
            />
        </div>
    );
};
